import React from "react";
import "./App.css";

function App() {
  return (
    <main>
      <h1>Solsol AS</h1>
      <h2>Hvem er vi?</h2>
      <div>
        Solsol AS består kun av Jo Mehmet Sollihagen som jobber som selvstendig
        it-konsulent innen fullstack utvikling med hovedvekt i front-end. Jo
        Mehmet er en dyktig teamleder og fullstack utvikler, med erfaring fra
        privat og offentlig sektor. Igjennom ulike prosjekter har han
        opparbeidet seg god kompetanse på flere ulike teknologier. Jo Mehmet har
        erfaring både med .NET og Java, men har i de senere årene speialisert
        seg på .NET. På frontend har han erfaring med en rekke rammeverk som
        f.eks. React, Angular og Knockout.
      </div>
      <h2>HAR DU BEHOV FOR RESSURSER</h2>
      <div>
        Ta kontakt på mail eller e-post som du finner nederst på siden. Jeg er
        for tiden i løpende fulltidsoppdrag, men har du behov for konsulenter,
        så er det bare å ta kontakt så hjelper jeg deg gjerne med å finne gode
        kandidater gjennom utviklernettverkene jeg er en del av.
      </div>
      <h2>NØKKELKOMPETANSE</h2>
      <ul className="key-competance">
        <li>
          Plattformer: <span>.NET, Node.js, Azure, Heroku, Java, Weblogic</span>
        </li>
        <li>
          Frontend:
          <span>
            {" "}
            HTML, CSS (LESS, SASS, Bootstrap), JavaScript (Knockout, Angular,
            React, jQuery, require.js, node.js, webpack, Gulp, Bower, ES5, ES
            2015), JSP, Razor.NET
          </span>
        </li>
        <li>
          Backend:{" "}
          <span>.NET, .NET MCV, .NET Core, SQL, mongoDB, Java + Spring</span>
        </li>
        <li>
          Tjenester/ integrasjon:
          <span> REST, Json, SOAP, WSDL, XSD, XML, CXF, Swagger</span>
        </li>
        <li>
          App utvikling:
          <span> Android, Facebook, Firefox-plugin</span>
        </li>
        <li>
          CMS:<span> Umbraco</span>
        </li>
        <li>
          Søk:{" "}
          <span>
            Search Engine Optimization, Google Analytics, Google Search Console
          </span>
        </li>
      </ul>
      <h2>Kontakt</h2>
      <div>
        <a href="mailto:jomehmet@solsol.no">jomehmet@solsol.no</a> |{" "}
        <a href="tel:+4740000215">+4740000215</a>
      </div>
    </main>
  );
}

export default App;
